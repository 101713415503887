async function generateJWT(secretKey) {
  const payload = {
    mercure: {
      publish: [process.env.REACT_APP_MERCURE_URL],
      subscribe: [process.env.REACT_APP_MERCURE_URL],
    },
  };

  // Helper to Base64 URL-encode
  const base64UrlEncode = (data) => btoa(JSON.stringify(data))
      .replace(/=/g, "")
      .replace(/\+/g, "-")
      .replace(/\//g, "_");

  const header = {
    alg: "HS256",
    typ: "JWT",
  };

  // Encode header and payload
  const encodedHeader = base64UrlEncode(header);
  const encodedPayload = base64UrlEncode(payload);

  const token = `${encodedHeader}.${encodedPayload}`;

  // Convert secret key to a CryptoKey
  const key = await crypto.subtle.importKey(
    "raw",
    new TextEncoder().encode(secretKey),
    { name: "HMAC", hash: "SHA-256" },
    false,
    ["sign"]
  );

  // Sign the token
  const signature = await crypto.subtle.sign(
    "HMAC",
    key,
    new TextEncoder().encode(token)
  );

  // Convert the signature to Base64 URL-encoded string
  const encodedSignature = btoa(
    String.fromCharCode(...new Uint8Array(signature))
  )
    .replace(/=/g, "")
    .replace(/\+/g, "-")
    .replace(/\//g, "_");

  return `${token}.${encodedSignature}`;
}

export default generateJWT;
