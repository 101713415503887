import React, { useEffect, useRef, useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import { useSelector } from "react-redux";
import { PulseLoader } from "react-spinners";
import { getItem } from "../../../services/LocaleStorage";
import { toast } from "react-toastify";
import { getRealUser, getUser } from "../../../app/user";
import http from "../../../services/Http";


const PopupMessageWithAdminP = ({ closeModal, conversation }) => {


    const [state, setData] = useState({
        messages: [],
        loading: false,
        sending: false,
        correspondant: conversation?.correspondant,
    }, [])
    const [messageContent, setMessageContent] = useState('')

    const token = getItem('bbbToken')
    const realUser = useSelector(getRealUser)
    const frontUser = useSelector(getUser)
    const [correspondant, setCorrespondant] = useState(null)

    /**
     * Chat
     */
    // Will display the toast notification only on the first message received
    const [notified, setNotified] = useState(false)
    const mercureUrl = process.env.REACT_APP_MERCURE_URL


    /**
     * Pagination
     */
    const conversationScrollableElement = useRef()
    const loadMoreConversationBtn = useRef()
    const [pagination, setPagination] = useState({
        page: 1,
        per_page: 5,
        count: null,
    })
    const [allMessagesAreSeen, setAllMessagesAreSeen] = useState(false)


    useEffect(() => {
        fetchMessages()

        if (!conversation && !correspondant) {
            fetchAdminP()
        }

        // Paginate on scroll to top
        conversationScrollableElement.current.addEventListener('scroll', () => {
            if (conversationScrollableElement.current.scrollTop == 0) {
                loadMoreConversationBtn.current.click()
            }
        })

        const initializeEventSource = async () => {
            const eventSource = await createEventSource()
            if (eventSource) {
                subscribeToConversation(eventSource)
    
                // Unsubscribe from the chat chanel o component did unmount
                return () => {
                    eventSource.close()
                }
            }
        }

        initializeEventSource();
    }, [correspondant])


    const conversationDate = (date = new Date()) => {
        var value = (new Intl.DateTimeFormat('fr-FR', { dateStyle: 'medium', timeStyle: 'medium' }).format(date));
        return value
    }

    const sortMessagesByCreatedAt = (messages) => {
        return messages.sort((a, b) => {
            var da = new Date(a.created_at)
            var db = new Date(b.created_at)
            if (da > db) return 1
            return -1
        })
    }

    const sendMessage = (e) => {
        e.preventDefault()
        if (!messageContent.trim()) {
            alert('Rien à envoyer!')
            return
        }

        const payload = {
            "sender_id": realUser.id,
            "receiver_id": state.correspondant.id == realUser.id ? frontUser.id : state.correspondant.id,
            "message_body": messageContent.trim(),
        }
        setData({ ...state, sending: true })

        fetch(`${process.env.REACT_APP_API_URL}/message/admins/send`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify(payload)
        }).then(response => {
            if (response.ok) {
                response.json().then(resData => {
                    const messages = state.messages
                    messages.push(resData)
                    setData(oldState => {
                        return { ...oldState, sending: false, messages }
                    })
                })
                setMessageContent('');
                document.getElementById('msg-content').value = ''
                scrollToBottom()
            } else if (!response.ok) {
                setData({ ...state, sending: false })
                toast.error('Une erreur s\'est produite! Veuillez verifier votre requete et re-essayez')
            }
        })
            .catch(e => { })

        // Uncomment to display toast notification when you send a message
        // toast.info('Message envoyé')
    }

    const createEventSource = async () => {
        // The subscriber subscribes to updates for the https://example.com/users/dunglas topic
        // and to any topic matching https://example.com/books/{id}
        const url = new URL(mercureUrl);
        var senderId = null
        var receiverId = null

        if (conversation) {
            senderId = conversation.sender.id
            receiverId = conversation.receiver.id
        } else {
            senderId = realUser.id
            receiverId = state.correspondant?.id
        }

        // Only subscribe if both of participants are defined
        if (!senderId || !receiverId || receiverId == senderId) return

        url.searchParams.append('topic', process.env.REACT_APP_API_URL + '/conversation/listen/admins-' + senderId + '_' + receiverId);
        // The URL class is a convenient way to generate URLs such as https://localhost/.well-known/mercure?topic=https://example.com/books/{id}&topic=https://example.com/users/dunglas

        const jwtToken = await generateJWT(process.env.REACT_APP_MERCURE_JWT_KEY);

        const options = {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
        };

        return new EventSource(url, options);
    }

    /**
     * 
     * @param {EventSource} eventSource 
     */
    const subscribeToConversation = (eventSource) => {
        // The callback will be called every time an update is published
        eventSource.onmessage = (res) => {
            // New message received
            const incommingMessage = JSON.parse(res.data)

            // Skip messages sent by me
            if (incommingMessage.sender_id == realUser.id) return

            const messages = state.messages
            messages.push(incommingMessage)
            setData(oldState => {
                return { ...oldState, sending: false, messages }
            })
            scrollToBottom()
            if (!notified) {
                toast.info('Nouveau message: ' + incommingMessage.body)
                setNotified(true)
            }
        };
    }

    const scrollToBottom = () => {
        const scrollingElement = document.querySelector('.scrollable')
        var scroller = 0
        const sc = setInterval(() => {
            scroller = scroller < scrollingElement.scrollHeight ? scroller + 5 : scroller
            scrollingElement.scrollTop = scroller
            if (scroller >= scrollingElement.scrollHeight) clearInterval(sc)
        }, 1);
    }

    const conversationMessages = () => {

        return (
            <React.Fragment>
                <div className="chat">
                    {
                        !(state.loading) ? ''
                            : <p className="text-center"> <PulseLoader color={'purple'} size={15} /> </p>
                    }
                    {sortMessagesByCreatedAt(state.messages).map(message =>
                        <div key={message.id} className={"cnv " + (message.sender_id == realUser.id ? 'me' : 'other')}>
                            <div className="name">
                                <h4>{message.sender_id == state.correspondant.id ? state.correspondant.display_name : realUser.display_name}</h4>
                                <span>{conversationDate(new Date(message.created_at))}</span>
                                {/* <span>Jan 19, 2021, 08:22 AM</span> */}
                            </div>
                            <div className="text">
                                {/* <p>Bonjour,Je ne sais pas affecter cette operation car je n’ai la piace comtable avec moi. Je crois que c’est du materiel. Je
                                    l’ai
                                    achete en 3 fois
                                    et je ne retrouve pas la facture </p> */}
                                <p>{message.body}</p>
                            </div>
                        </div>
                    )}
                </div>
                <form className="add-msg" onSubmit={e => sendMessage(e)}>
                    <div>
                        <textarea id="msg-content" onChange={e => setMessageContent(e.target.value)} type="text" placeholder="Envoyer une réponse ..." defaultValue={messageContent}></textarea>
                    </div>
                    <div>
                        {
                            state.sending ?
                                <PulseLoader color={'purple'} size={10} />
                                : <ArrowUpwardIcon />
                        }
                        <input type="submit" />
                    </div>
                </form>
            </React.Fragment>
        )
    }

    /**
     * 
     * @param {object} conversationID 
     * @param {int} page 
     * @param {int} perPage 
     */
    const fetchMessages = async (page = 1, perPage = 10) => {
        if (!conversation) {
            return
        }
        setData({ ...state, loading: true })
        fetch(`${process.env.REACT_APP_API_URL}/conversation/${conversation.id}/messages?page=${page}&per_page=${perPage}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        }).then(response => {
            if (response.ok) {
                response.json().then(data => {
                    const messages = [...state.messages, ...data.data]
                    setData((oldState) => ({ ...oldState, messages: messages, loading: false }))
                    setPagination({
                        page: data.page,
                        per_page: data.per_page,
                        count: data.count,
                    })
                    if (page == 1)
                        scrollToBottom()
                })
            } else if (!response.ok) {
                setData({ ...state, loading: false })
                return new Promise((resolve, reject) => reject('Une erreur s\'est produite! Veuillez verifier votre requete et re-essayez'))
            }
        })
            .catch(e => { })
    }

    const loadMore = () => {
        // 
        var data = state.messages
        var _data = pagination
        if (pagination.page * pagination.per_page < pagination.count) {
            fetchMessages(pagination.page + 1, pagination.per_page)
        } else {
            setAllMessagesAreSeen(true)
        }
    }

    /**
     * Returns void
     * Comments inside
     */
    const fetchAdminP = () => {

        if (frontUser.id != realUser.id) {
            // This case means admin P is logged to the admin N space
            // So the sender will be the connected admin P
            // And the receier will be the admin N owner of the space in subject
            const _corresp = frontUser.role == 'admin_n' ? frontUser : realUser
            setCorrespondant(_corresp)
            setData((oldState) => ({ ...oldState, correspondant: _corresp }))
            return
        }

        // Otherwise fetch the admins P and set the first one as correspondant
        // While waiting to display all of them to the admin N allowing him to choose
        // which admin P he would like to contact
        http.get('/admins_p', {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        })
            .then(data => {
                setData((oldState) => ({ ...oldState, correspondant: data[0] }))
                setCorrespondant(data[0])
            })
            .catch(err => {
                alert('Une erreur s\'est produite, en consequence, vous ne pourrez pas envoyer des message!')
            })
    }

    return (
        <div className="w-full h-full fixed top-0 left-0 flex text-black z-500000 bg-opacity-50 bg-black items-center desktop-operation">
            <div className="w-2/3 mx-auto bg-white rounded-lg shadow-xl p-8 z-100">
                <div className="parent-table w-full">

                    <div className="w-full h-full fixed top-0 left-0 flex text-black z-500000 bg-opacity-50 bg-black items-center desktop-operation">
                        <div ref={conversationScrollableElement} className="scrollable w-2/3 mx-auto overflow-y-scroll max-h-96 2xl:max-h-full 2xl:overflow-y-hidden bg-white rounded-lg shadow-xl p-8 z-100 relative">
                            <button type="button" className="float-right pt-0" onClick={closeModal}><CloseIcon /></button>
                            <div className="w-full flex flex-col h-full">

                                <section className="flex flex-col msg-diag w-full">
                                    <button ref={loadMoreConversationBtn} type="button" className="" onClick={() => loadMore()}></button>

                                    <div className="msg w-full">

                                        <h3>Messagerie ADMIN</h3>
                                        {
                                            !allMessagesAreSeen ? '' : <p className="text-center py-2 text-lilaFonce">C'est tout!</p>
                                        }
                                        {/*  */}
                                        {conversationMessages()}
                                    </div>

                                </section>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default PopupMessageWithAdminP